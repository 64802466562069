import { DefaultRoute } from '../router/routes'
// import { store } from '../redux/store'
import CryptoJS from 'crypto-js'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
	const today = new Date()
	return (
		/* eslint-disable operator-linebreak */
		date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
		/* eslint-enable */
	)
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }, locale = 'en') => {
	if (!value) return value
	const locales = { ru: 'ru-RU', ro: 'ro-RO', en: 'en-En' }
	return new Intl.DateTimeFormat(locales[locale], formatting).format(new Date(value))
}

export const formatDateYmd = (date) => {
	const dateObj = new Date(date)
	const formattedDate = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`
	return formattedDate
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
	const date = new Date(value)
	let formatting = { month: 'short', day: 'numeric' }

	if (toTimeForCurrentDay && isToday(date)) {
		formatting = { hour: 'numeric', minute: 'numeric' }
	}

	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatEmailDate = (dateToFormat, time, locale = 'en') => {
	if (!dateToFormat) return dateToFormat
	const locales = { ru: 'ru-RU', ro: 'ro-RO', en: 'en-En' }
	const date = new Date(dateToFormat)
	const today = new Date()
	const oneWeekLater = new Date()
	oneWeekLater.setDate(today.getDate() - 7)

	if (date <= today && date >= oneWeekLater) {
		const formattedDate = new Intl.DateTimeFormat(locales[locale], {
			weekday: 'short'
		}).format(date)
		return `${formattedDate} ${time}`
	}

	if (date.getFullYear() === today.getFullYear()) {
		return new Intl.DateTimeFormat(locales[locale], {
			month: 'short',
			day: 'numeric'
		}).format(date)
	}

	return new Intl.DateTimeFormat(locales[locale], {
		month: 'short',
		day: 'numeric',
		year: 'numeric'
	}).format(date)
}

const secretKey = process.env.REACT_APP_ENC_KEY

// Encrypt data
export const encryptData = (data) => {
	if (!data) {
		console.error('No data provided for encryption')
		return null
	}
	return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString()
}

// Decrypt data
export const decryptData = (encryptedData) => {
	if (!encryptedData) {
		console.error('No encrypted data provided for decryption')
		return null
	}
	try {
		const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
	} catch (error) {
		console.error('Decryption error:', error)
		return null
	}
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
	try {
		// Check if encrypted user data exists in localStorage
		const encryptedData = localStorage.getItem('userData')
		if (!encryptedData || typeof encryptedData !== 'string') {
			console.warn('No valid encrypted user data found in localStorage')
			return false // Return false if no data or invalid type
		}

		// Attempt to decrypt user data
		const userData = decryptData(encryptedData)
		if (!userData || typeof userData !== 'object') {
			console.warn('Decryption resulted in invalid user data')
			return false // Return false if decryption fails or data is invalid
		}

		return true // User is logged in if valid userData exists
	} catch (error) {
		console.error('Decryption error in isUserLoggedIn:', error)
		return false // Return false if any error occurs
	}
}

export const getUserData = () => {
	try {
		// Retrieve encrypted user data from localStorage
		const encryptedData = localStorage.getItem('userData')
		if (!encryptedData || typeof encryptedData !== 'string') {
			console.warn('No valid encrypted user data found in localStorage')
			return null // Return null if no data or invalid type
		}

		// Decrypt the user data
		const userData = decryptData(encryptedData)
		if (!userData || typeof userData !== 'object') {
			console.warn('Decryption resulted in invalid user data')
			return null // Return null if decryption fails or data is invalid
		}

		return userData // Return decrypted user data
	} catch (error) {
		console.error('Decryption error in getUserData:', error)
		return null // Return null if any error occurs
	}
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
	/**
	 * Redirect all roles to same route for now
	 */
	if (userRole === 'admin') return DefaultRoute
	if (userRole === 'manager') return DefaultRoute
	if (userRole === 'employee') return '/lms/courses'
	if (userRole === 'user') return '/mailbox'
	//if (userRole === 'client') return '/access-control'
	return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary25: '#7367f01a', // for option hover bg-color
		primary: '#7367f0', // for selected option bg-color
		neutral10: '#7367f0', // for tags bg-color
		neutral20: '#ededed', // for input border-color
		neutral30: '#ededed' // for input hover border-color
	}
})

export const generatePassword = (length = 12) => {
	const lowercase = 'abcdefghijklmnopqrstuvwxyz'
	const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
	const digits = '0123456789'

	const allChars = lowercase + uppercase + digits

	let password = ''

	// Ensure the password contains at least one of each type of character
	password += lowercase.charAt(Math.floor(Math.random() * lowercase.length))
	password += uppercase.charAt(Math.floor(Math.random() * uppercase.length))
	password += digits.charAt(Math.floor(Math.random() * digits.length))

	// Fill the rest of the password length with random characters
	for (let i = password.length; i < length; i++) {
		password += allChars.charAt(Math.floor(Math.random() * allChars.length))
	}

	// Shuffle the password to avoid predictable patterns
	return password
		.split('')
		.sort(() => 0.5 - Math.random())
		.join('')
}

const cyrillicToLatinMap = {
	А: 'A',
	Б: 'B',
	В: 'V',
	Г: 'G',
	Д: 'D',
	Е: 'E',
	Ё: 'Yo',
	Ж: 'Zh',
	З: 'Z',
	И: 'I',
	Й: 'Y',
	К: 'K',
	Л: 'L',
	М: 'M',
	Н: 'N',
	О: 'O',
	П: 'P',
	Р: 'R',
	С: 'S',
	Т: 'T',
	У: 'U',
	Ф: 'F',
	Х: 'Kh',
	Ц: 'Ts',
	Ч: 'Ch',
	Ш: 'Sh',
	Щ: 'Shch',
	Ъ: '',
	Ы: 'Y',
	Ь: '',
	Э: 'E',
	Ю: 'Yu',
	Я: 'Ya',
	а: 'a',
	б: 'b',
	в: 'v',
	г: 'g',
	д: 'd',
	е: 'e',
	ё: 'yo',
	ж: 'zh',
	з: 'z',
	и: 'i',
	й: 'y',
	к: 'k',
	л: 'l',
	м: 'm',
	н: 'n',
	о: 'o',
	п: 'p',
	р: 'r',
	с: 's',
	т: 't',
	у: 'u',
	ф: 'f',
	х: 'kh',
	ц: 'ts',
	ч: 'ch',
	ш: 'sh',
	щ: 'shch',
	ъ: '',
	ы: 'y',
	ь: '',
	э: 'e',
	ю: 'yu',
	я: 'ya'
}

const transliterate = (string) => {
	return string
		.split('')
		.map((char) => cyrillicToLatinMap[char] || char)
		.join('')
}

export const generateUsername = (name, domain = null) => {
	// Convert to lowercase
	let username = name.toLowerCase()

	// Replace spaces with dots
	username = username.replace(/\s+/g, '.')

	username = transliterate(username)
	if (domain) {
		return `${username}@${domain}`
	} else {
		return username
	}
}

/**
 * Transform preview url to video url
 */
export const cleanURL = (url) => {
	// Remove the "preview" word
	let cleanedURL = url.replace(/preview_/, '')

	// Remove the ".png" extension
	cleanedURL = cleanedURL.replace(/\.png$/, '')

	return cleanedURL
}

export const getFileTypeFromUrl = (url) => {
	const extension = url.split('.').pop().split(/\#|\?/)[0]
	const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff']
	const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'wmv', 'flv']

	if (imageExtensions.includes(extension)) {
		return 'image'
	} else if (videoExtensions.includes(extension)) {
		return 'video'
	} else {
		return 'unknown'
	}
}
